.single {
  
  position: fixed;
  // animation: slidein 0.15s ease-in-out;

  > header.app-bar {
    left: auto;
    // display: flex;
    // flex-flow: row nowrap;
    // align-items: center;
    // position: fixed;
    // left: 0;
    // top: 0;
    // width: 100%;
    // height: var(--header-height);
    // background: var(--primary);

    .title {
      font-weight: 500;
      font-size: 1rem;
      margin: 0 0.5rem;
      white-space: nowrap;
      overflow-x: auto;
    }

    .title.top {
      width: 100%;
    }

    .single > header > svg {
      transform: scale(0.9);
    }
  }

  > div {
    position: fixed;
    top: 0;
    width: 100%;
    overflow-y: hidden;
    height: calc(100% - var(--header-height));
    margin: var(--header-height) 0 0;
    background-color: var(--card-background-color);

    > div {
      height: 100%;

      article {
        height: 100%;
      }
    }
  }
}
