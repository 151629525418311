.photo-view {
  // position: fixed;
  // top: 0;
  // width: 100%;
  // overflow-y: auto;
  // height: calc(100% - var(--header-height));
  // margin: var(--header-height) 0 0;
  // background-color: var(--card-background-color);
  margin-bottom: 0;
  height: 100%;

  > header {
    padding: 0.5rem 1rem 0;
    > * {
      margin: 0;
    }
  }

  // 投稿の見せ方
  > main {
    // padding: 1rem;

    > :last-child {
      margin-bottom: 0;
    }

    // 画像
    .image-box {
      // margin-left: calc(50% - 50vw);
      // margin-right: calc(50% - 50vw);
      min-height: calc(100vh - var(--header-height));
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
      }
    }
  }

  > footer.actions {
    padding: 0 0.5rem;
    transition: opacity 0.1s ease;
    visibility: hidden;
    opacity: 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    height: var(--footer-height);
    background-color: var(--background-color);

    &.show {
      visibility: visible;
      opacity: 0.6;
    }
  }
}
