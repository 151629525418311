.single-view {
  // position: fixed;
  // top: 0;
  // width: 100%;
  // overflow-y: auto;
  // height: calc(100% - var(--header-height));
  // margin: var(--header-height) 0 0;
  // background-color: var(--card-background-color);
  margin-bottom: 0;
  height: 100%;

  > header {
    padding: 0.5rem 1rem 0;
    > * {
      margin: 0;
    }
  }

  // 投稿の見せ方
  > main {
    padding: 1rem;

    > :last-child {
      margin-bottom: 0;
    }

    // 画像
    .image-box {
      margin-left: calc(50% - 50vw);
      margin-right: calc(50% - 50vw);

      img {
        width: 100%;
      }
    }
  }

  > footer {
    padding: 0 1rem;
    margin-bottom: 1rem;

    .categories {
      display: flex;
      flex-flow: row wrap;
      column-gap: 0.5rem;
    }

    .single-meta {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      column-gap: 0.5rem;

      // span:not(:first-child) {
      //   margin-left: 1em;
      // }
      span {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
      }
      span.categories {
        display: flex;
        flex-flow: row wrap;
      }

      i[class^=icon-] {
        padding-right: 0.5rem;
      }

      i{
        font-size: 0.875rem;
        line-height: 1.5;
        max-height: 38px;
        > svg {
          transform: scale(0.5);
        }
      }

      .actions {
        svg {
          transform: scale(0.75);
          // transition: all 1s;
          fill: var(--primary-800);
          animation: fadeAndScaleIn75 0.2s ease-in-out;
        }
      }
    }
  }
}
