.settings {

  dialog h4 {
    margin: 0;
  }

  article > header,
  article > section {
    margin-bottom: calc(var(--block-spacing-vertical) * 0.5);
  }

  td {
    text-align: right;
  }

  .data-loading article {
    width: 80%;
    max-width: 320px;
  }

  .data-loading .counter {
    text-align: center;
  }
}
