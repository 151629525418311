.list {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100%;
  // top: 0;
  // width: 100%;
  // height: calc(100% - var(--header-height));
  // margin: var(--header-height) 0 0;
  background-color: var(--background-color);
  // animation: slidein 0.15s ease-in-out;

  > header.app-bar {
    left: auto;
    // display: flex;
    // flex-flow: row nowrap;
    // align-items: center;
    // position: fixed;
    // left: 0;
    // top: 0;
    // width: 100%;
    // height: var(--header-height);
    // background: var(--primary);
    z-index: 100;

    .title {
      font-weight: 500;
      font-size: 1rem;
      margin: 0 0.5rem;
      white-space: nowrap;
      overflow-x: auto;
    }

    .title.top {
      width: 100%;
    }

  }

  > div {
    position: fixed;
    top: 0;
    width: 100%;
    overflow-y: hidden;
    height: calc(100% - var(--header-height));
    margin: var(--header-height) 0 0;
    background-color: var(--background-color);
    padding: 0;
  }

}