@font-face {
  font-family: 'Noto Sans JP';
  src: url(./NotoSansJP/NotoSansJP-Light.otf);
  font-weight: 300;
}

@font-face {
  font-family: 'Noto Sans JP';
  src: url(./NotoSansJP/NotoSansJP-Medium.otf);
  font-weight: 500;
}

/**
icons
**/
@font-face {
  font-family: 'icomoon';
  src:  url('./icon/icomoon.eot?x67sl7');
  src:  url('./icon/icomoon.eot?x67sl7#iefix') format('embedded-opentype'),
    url('./icon/icomoon.ttf?x67sl7') format('truetype'),
    url('./icon/icomoon.woff?x67sl7') format('woff'),
    url('./icon/icomoon.svg?x67sl7#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  /* speak: never; */
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-label:before {
  content: "\e900";
}
.icon-date:before {
  content: "\e901";
}
.icon-folder:before {
  content: "\e902";
}
