article.item {
  margin: 0.5rem 0;
  /* max-width: calc(100% - 2rem); */
  padding: 0;
  border-radius: 0;
  background: transparent;
  box-shadow: none;

}

article.item > div {
  /* margin: 1rem 0; */
  /* max-width: calc(100% - 2rem); */
  padding: 0.5rem 1rem;
  /* border-radius: var(--border-radius); */
  background: var(--card-background-color);
  /* box-shadow: var(--card-box-shadow); */
}

article.item header {
  margin: 0;
  padding: 0 0 0.5rem;
  height: 40px;
  /* overflow-x: hidden; */
}

article.item header h4 {
  color: var(--primary-500);
  white-space: nowrap;
  overflow-x: hidden;
  font-size: 1.125rem;
}

article.item .excerpt {
  height: 168px;
  overflow-y: hidden;
}

article.item p{
  margin: 0;
  padding: 0 0 1rem;
}

article.item footer{
  margin: 0;
  padding: 0 0;
  height: 40px;
  box-shadow: 0 -8px 3px -3px var(--card-background-color);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  // color: var(--muted-color);
  color: var(--mat-grey-500);

  span:not(:first-child) {
    margin-left: 1em;
    // line-height: 40px;
  }

  span {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  span > * {
    line-height: 40px;
  }

  i[class^=icon-] {
    // font-size: 0.875rem;
    line-height: 40px;
    padding-right: 0.5rem;
  }
  i.icon-label:before {
    transform: rotate(-45deg);
    display: inline-block;
  }

  
  svg {
    transform: scale(0.6);
    // transition: all 1s;
    // fill: var(--primary-800);
    // animation: fadeAndScaleIn75 0.2s ease-in-out;
  }
}