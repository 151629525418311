.dens-list-item {

  display: flex;
  padding: 0 0.5rem;
  // padding-right: 1rem;
  flex-flow: row nowrap;
  align-items: center;
  background-color: var(--card-background-color);
  border-top: 1px solid var(--card-border-color);

  &.has-right-icon {
    padding-right: 0;
  }

  svg {
    transform: scale(0.75);
  }

  i {
    font-size: 1.2rem;
    transform: rotate(-45deg);
    padding: 0 0.5rem;
  }

  .words {
    overflow-x: auto;
    white-space: nowrap;
  }

  .count {
    width: calc(calc(var(--font-size) * var(--line-height)) * 3);
    // width: 3rem;
    text-align: right;
    padding-right: 1rem;
    // display: flex;
    // flex-flow: row nowrap;
    // justify-content: space-between;
    // align-items: center;
    svg {
      transform: scale(0.5);
      margin: -10px;
    }
  }

  .view-count {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    column-gap: 0.5rem;
    border-right: 1px solid var(--muted-border-color);
    border-left: 1px solid var(--muted-border-color);
  }

  // & {
  //   border-top: 1px solid var(--card-border-color);
  // }

  .del-button {
    // padding-right: 0.5rem;
    text-align: center;
    width: 2rem;
    height: 2rem;
    line-height: 2;
  }
}