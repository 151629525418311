.no-data-found {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  margin: 2rem 0;

  h1 {
    font-size: 8rem;
  }
  
  p {
    color: var(--muted-color);
  }

  h1, h2, h3, p {
    text-align: center;
  }
}