#search {
  // margin-top: 0;
  .loading {
    position: absolute;
    top: 0;
    width: 100%;
  }

  .no-result {
    position: absolute;
    top: 1rem;
    width: 100%;
    margin: 0 auto;
    animation: fadeAndScaleIn 0.05s ease-in-out 0s 1;
    // animation: fadeAndScaleInOut 2s ease-in-out 0s 1;
    transition: 0.1s;

    p {
      text-align: center;
      margin: 0 10%;
      padding: 1rem;
      background-color: var(--background-color);
      // ba
      opacity: 0.8;
    }
  }
}