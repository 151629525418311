.tag-chips {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  position: relative;
  i {
    transform: rotate(-45deg);
    display: inline-block;
    height: 2rem;
    width: 1rem;
    line-height: 2.25;
    position: absolute;
    top: 0;
  }
  ul.chip-list {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 0;
    padding: 0;
    
    li.chip-item {
      &:first-child {
        margin-left: 1.5rem;
      }

      list-style: none;
      margin-left: calc(var(--typography-spacing-vertical) * 0.25);
      margin-right: calc(var(--typography-spacing-vertical) * 0.25);
      padding: 0.25rem 0.75rem;
      background-color: var(--secondary-focus);
      // background-color: var(--mat-grey-800);
      border-radius: 1rem;
    }
  }
}