.media-cell {
  padding: 1px;
  // margin: 0 -1px;
  // position: relative;
  .media-item {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    position: relative;

    i {
      position: absolute;
      bottom: 0;
      right: 0;
      svg {
        transform: scale(0.7);
      }
    }
  }
  // &:nth-child(odd) {
  //   padding: 2px 1px 0 0;
  // }
  // &:nth-child(even) {
  //   padding: 2px 0 0 1px;
  // }
  &:nth-child(3n) {
    padding-right: 0;
  }
  &:nth-child(3n+1) {
    padding-left: 0;
  }
}