.app-bar {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: var(--header-height);
  // background: var(--primary);
  background-color: var(--card-background-color);
  /* box-shadow: 0 0 6px var(--contrast); */
  /* padding: 0 1rem; */
  /* z-index: 100; */
  overflow-x: auto;
  .title {
    color: var(--primary) !important;
    font-weight: 500;
    font-size: 1.22rem;
    margin: 0 0.5rem;

    white-space: nowrap;
    overflow-x: auto;
    small {
      padding-left: 0.25rem;
      font-size: 0.875rem;
      font-weight: 200;
      overflow-x: auto;
      // animation: fadeAndScaleIn 1s ease;
    }
  }
  > svg {
    transform: scale(0.9);
    margin-right: 0.5rem;

    &#biohazard-symbol {
      transform: scale(0.8);
      fill: var(--primary) !important;
      background-color: var(--background-color);
      border-radius: 50%;
      flex: 1 0 auto;
      max-width: 40px;
    }
  }

  #search-form {
    margin: 0 0.5rem;
    display: flex;
    flex: 1 0 auto;
    padding: 0 0.25rem;
    width: calc(100% - 1rem);
  }

  #search-word {
    margin: 0;
    // padding: 0 0.25rem;
    padding-top: 0;
    padding-bottom: 0;
    // height: calc(1rem * var(--line-height)/* + var(--form-element-spacing-vertical) * 2*/ + var(--border-width) * 2);
    // height: calc(1rem * var(--line-height) * var(--line-height));
    height: 2rem;
    max-width: 100%;
  }

  #tag-filter {
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
    height: 2rem;
    max-width: 30%;
    // margin-right: 4px;
    padding-left: 1.5rem;
    background-image: var(--icon-search);
    background-position: center left 0.25rem;
    background-size: 1rem auto;
    background-repeat: no-repeat;
  }

  #tag-sort, .sort {
    margin: 0 0.5rem 0;
    // padding-top: 0;
    // padding-bottom: 0;
    padding: 0 0.5rem;
    height: 2rem;
    max-width: 3rem;
    background-image: none;
    font-size: 0.8rem;
    // text-align: center !important;
    display: flex;
    justify-content: center;
    align-items: center;
    option {
      text-align: center;
    }
  }

  .cancel-button {
    margin: 0;
    padding: 0 0.5rem 0;
    // display: inline-block;
    display: none;
    width: fit-content;
    border: none;

    &.canceable {
      display: inline-block;
    }
  }
}
