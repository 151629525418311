.navigation-bar {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: var(--footer-height);
  padding: 0;
  background-color: var(--background-color);
  box-shadow: 0 0 1px var(--contrast);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  ul {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    margin: 0 0 0 0;
    padding: 0;
    li {
      list-style: none;
      text-align: center;
      margin: 0;
      padding: 0;
      transform: scale(0.9);
    }
  }
}
